import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Trading/Spot/Trading/Form.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Trading/Spot/Orders.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Partials/Trading/Layout.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, withCtx as _withCtx } from "vue";
import { Status, StatusType } from "@injectivelabs/utils";
export default /* @__PURE__ */ _defineComponent({
  __name: "[spot]",
  setup(__props) {
    false && {};
    const spotStore = useSpotStore();
    const walletStore = useWalletStore();
    const { $onError } = useNuxtApp();
    const filterByCurrentMarket = ref(false);
    const market = ref(void 0);
    const fetchStatus = reactive(new Status(StatusType.Loading));
    onWalletConnected(() => {
      filterByCurrentMarket.value = false;
      refreshSubaccountDetails();
    });
    function onLoad(pageMarket) {
      filterByCurrentMarket.value = false;
      Promise.all([
        spotStore.streamTrades(pageMarket.marketId),
        spotStore.streamOrderbookUpdate(pageMarket.marketId)
      ]).catch($onError);
      market.value = pageMarket;
      refreshSubaccountDetails();
    }
    function refreshSubaccountDetails() {
      if (!market.value) {
        return;
      }
      spotStore.cancelSubaccountStream();
      const fetchOptions = filterByCurrentMarket.value ? {
        filters: {
          marketIds: [market.value.marketId]
        }
      } : void 0;
      const marketId = filterByCurrentMarket.value ? market.value.marketId : void 0;
      fetchSubaccountOrderDetails(fetchOptions);
      streamSubaccountOrderDetails(marketId);
    }
    function fetchSubaccountOrderDetails(fetchOptions) {
      var _a;
      fetchStatus.setLoading();
      const marketIds = (_a = fetchOptions == null ? void 0 : fetchOptions.filters) == null ? void 0 : _a.marketIds;
      Promise.all([
        spotStore.fetchSubaccountOrders(marketIds),
        spotStore.fetchSubaccountOrderHistory(fetchOptions),
        spotStore.fetchSubaccountTrades(fetchOptions)
      ]).catch($onError).finally(() => fetchStatus.setIdle());
    }
    function streamSubaccountOrderDetails(marketId) {
      Promise.all([
        spotStore.streamSubaccountTrades(marketId),
        spotStore.streamSubaccountOrders(marketId),
        spotStore.streamSubaccountOrderHistory(marketId)
      ]);
    }
    watch(
      () => walletStore.isUserWalletConnected,
      (isConnected) => {
        if (isConnected) {
          fetchStatus.setLoading();
        }
      }
    );
    return (_ctx, _cache) => {
      const _component_PartialsTradingSpotTradingForm = __nuxt_component_0;
      const _component_PartialsTradingSpotOrders = __nuxt_component_1;
      const _component_PartialsTradingLayout = __nuxt_component_2;
      return _openBlock(), _createBlock(_component_PartialsTradingLayout, {
        "is-spot": "",
        onLoaded: onLoad
      }, {
        "trading-form": _withCtx(() => [
          _unref(market) ? (_openBlock(), _createBlock(_component_PartialsTradingSpotTradingForm, {
            key: 0,
            market: _unref(market)
          }, null, 8, ["market"])) : _createCommentVNode("", true)
        ]),
        orders: _withCtx(() => [
          _unref(market) ? (_openBlock(), _createBlock(_component_PartialsTradingSpotOrders, {
            key: 0,
            filterByCurrentMarket: _unref(filterByCurrentMarket),
            "onUpdate:filterByCurrentMarket": [
              _cache[0] || (_cache[0] = ($event) => _isRef(filterByCurrentMarket) ? filterByCurrentMarket.value = $event : null),
              refreshSubaccountDetails
            ],
            market: _unref(market),
            status: _unref(fetchStatus)
          }, null, 8, ["filterByCurrentMarket", "market", "status"])) : _createCommentVNode("", true)
        ]),
        _: 1
      });
    };
  }
});
